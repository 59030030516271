import { BaseModel } from "../base.model";
import { DBFactory } from "classes/database/db_factory";
import {
  EducationResource,
  EducationResources,
} from "../education-resources/education-resource.model";
import { ClassSessionPrivateNotes } from "./class-session-private-notes.model";

type ClassSessionConstructorParams = {
  id?: string;
  userId: string;
  classroomId: string;
  classroomName: string;
  name: string;
  scheduledAtTimestamp: number;
  resources: EducationResources;
  privateNotes: ClassSessionPrivateNotes;
  publicNotes: any;
  meetingUrl?: string;
  emailLessonReminder: boolean;
  displayOrder?: number;
  isArchived?: boolean;
};

export type ClassSessions = ClassSession[];

export class ClassSession extends BaseModel {
  id?: string;
  userId: string;
  classroomId: string;
  classroomName: string;
  name: string;
  scheduledAtTimestamp: number;
  resources: EducationResources;
  privateNotes: ClassSessionPrivateNotes;
  publicNotes: any;
  meetingUrl?: string;
  emailLessonReminder: boolean;
  displayOrder?: number;
  isArchived: boolean;

  constructor(data: ClassSessionConstructorParams) {
    super(data);

    this.id = data.id;
    this.userId = data.userId;
    this.classroomId = data.classroomId;
    this.classroomName = data.classroomName;
    this.name = data.name;
    this.scheduledAtTimestamp = data.scheduledAtTimestamp;
    this.resources = data.resources;
    this.privateNotes = data.privateNotes;
    this.publicNotes = data.publicNotes;
    this.meetingUrl = data.meetingUrl;
    this.emailLessonReminder = data.emailLessonReminder;
    this.displayOrder = data.displayOrder;
    this.isArchived = data.isArchived ?? false;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `/classSessions`,
      path: `/classSessions/${this.id}`,
    };
  }

  static default({
    userId,
    classroomId,
    classroomName,
    name,
  }: {
    userId: string;
    classroomId: string;
    classroomName: string;
    name: string;
  }) {
    return new ClassSession({
      userId: userId,
      classroomId: classroomId,
      classroomName: classroomName,
      name: name,
      scheduledAtTimestamp: new Date().getTime(),
      resources: [],
      privateNotes: ClassSessionPrivateNotes.default({
        userId: userId,
      }),
      publicNotes: {},
      emailLessonReminder: false,
    });
  }

  static fromMap(map: any) {
    if (map.privateNotes) {
      map.privateNotes = ClassSessionPrivateNotes.fromMap(map.privateNotes);
    } else {
      map.privateNotes = ClassSessionPrivateNotes.default({
        userId: map.userId,
        classSessionId: map.id,
      });
    }

    if (map.resources) {
      map.resources = map.resources.map((resource: any) =>
        EducationResource.fromMap(resource)
      );
    }

    return new ClassSession(map);
  }

  toMap(): ModelDatabaseData {
    const map = super.toMap();
    delete map["privateNotes"];

    return map;
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);

    if (this.privateNotes) {
      this.privateNotes.classSessionId = this.id;
      await this.privateNotes.save();
    }
  }

  async delete() {
    this.isArchived = true;
    await this.save();
  }
}
