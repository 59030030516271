import { DBFactory } from "~/classes/database/db_factory";
import {
  Observable,
  catchError,
  combineLatest,
  map,
  of,
  switchMap,
} from "rxjs";
import { ClassSession, ClassSessions } from "./class-session.model";
import { ClassSessionPrivateNotes } from "./class-session-private-notes.model";

export class ClassSessionsService {
  static async get({
    classSessionId,
    includePrivateNotes = false,
  }: {
    classSessionId: string;
    includePrivateNotes?: boolean;
  }) {
    const db = DBFactory.createDatabase();
    const data = await db.get({
      collection: `/classSessions`,
      path: `/classSessions/${classSessionId}`,
    });

    if (includePrivateNotes) {
      try {
        const notesData = await db.get({
          collection: `/classSessions/${classSessionId}/privateNotes`,
          path: `/classSessions/${classSessionId}/privateNotes/default`,
        });

        data.privateNotes = notesData;
      } catch (error) {
        // empty
      }
    }

    return ClassSession.fromMap(data);
  }

  static async fetchClassroomClassSessions({
    userId,
    classroomId,
  }: {
    userId: string;
    classroomId: string;
  }): Promise<ClassSessions> {
    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        collection: `/classSessions`,
        path: `/classSessions`,
      },
      [
        {
          type: "where",
          operator: "==",
          field: "userId",
          value: userId,
        },
        {
          type: "where",
          operator: "==",
          field: "classroomId",
          value: classroomId,
        },
        {
          type: "orderBy",
          field: "scheduledAtTimestamp",
          direction: "asc",
        },
      ]
    );

    return data.map((classSession: any) => {
      return ClassSession.fromMap(classSession);
    });
  }

  static streamPrivateNotes({
    classSessionId,
  }: {
    classSessionId: string;
  }): Observable<ClassSessionPrivateNotes> {
    const db = DBFactory.createDatabase();
    return db
      .streamRecord({
        collection: `/classSessions/${classSessionId}/privateNotes`,
        path: `/classSessions/${classSessionId}/privateNotes/default`,
      })
      .pipe(
        map((data) => {
          return ClassSessionPrivateNotes.fromMap(data);
        })
      );
  }

  static streamClassSessions({
    classroomId,
    userId,
    includePrivateNotes = false,
  }: {
    classroomId: string;
    userId?: string;
    includePrivateNotes?: boolean;
  }) {
    const query = [
      {
        type: "where",
        field: "classroomId",
        value: classroomId,
        operator: "==",
      },
      {
        type: "where",
        field: "isArchived",
        value: false,
        operator: "==",
      },
    ] as ModelQueryConfig;

    if (userId) {
      query.push({
        type: "where",
        field: "userId",
        value: userId,
        operator: "==",
      });
    }

    query.push({
      type: "orderBy",
      field: "scheduledAtTimestamp",
      direction: "asc",
    });

    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          path: "/classSessions",
          collection: `/classSessions`,
        },
        query
      )
      .pipe(
        switchMap((data) => {
          if (includePrivateNotes != true || data.length == 0) {
            return of(data);
          }

          const streams = data.map((classSessionData) => {
            return db
              .streamRecord({
                collection: `/classSessions/${classSessionData.id}/privateNotes`,
                path: `/classSessions/${classSessionData.id}/privateNotes/default`,
              })
              .pipe(
                map((notesData) => {
                  classSessionData.privateNotes = notesData;
                  return classSessionData;
                }),
                catchError((error: any) => {
                  if (error.code == "permission-denied") {
                    return of(classSessionData);
                  }

                  throw error;
                })
              );
          });

          return combineLatest(streams);
        }),
        map((data) => {
          return data
            .map((classSessionData) => ClassSession.fromMap(classSessionData))
            .sort((a, b) => {
              // Sort by display order if htere are any then by scheduledAtTimestamp
              if (a.displayOrder || b.displayOrder) {
                return (a.displayOrder ?? 0) - (b.displayOrder ?? 0);
              }

              return a.scheduledAtTimestamp - b.scheduledAtTimestamp;
            });
        }),
        catchError((error) => {
          console.log("Catching an error inside of streamClassSessions");
          console.error(error);
          return of([]);
        })
      );
  }
}
