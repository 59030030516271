import { BaseModel } from "../base.model";
import { DBFactory } from "classes/database/db_factory";

type ClassSessionPrivateNotesConstructorParams = {
  classSessionId?: string;
  userId: string;
  notes: any;
};

export class ClassSessionPrivateNotes extends BaseModel {
  classSessionId?: string;
  userId: string;
  notes: any;

  constructor(data: ClassSessionPrivateNotesConstructorParams) {
    super(data);

    this.classSessionId = data.classSessionId;
    this.userId = data.userId;
    this.notes = data.notes;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `/classSessions/${this.classSessionId}/privateNotes`,
      path: `/classSessions/${this.classSessionId}/privateNotes/default`,
    };
  }

  static default({
    classSessionId,
    userId,
  }: {
    classSessionId?: string;
    userId: string;
  }) {
    return new ClassSessionPrivateNotes({
      classSessionId,
      userId,
      notes: {},
    });
  }

  static fromMap(data: any) {
    return new ClassSessionPrivateNotes({
      classSessionId: data.classSessionId,
      userId: data.userId,
      notes: data.notes,
    });
  }

  toMap(): ModelDatabaseData {
    return {
      id: "default",
      classSessionId: this.classSessionId,
      userId: this.userId,
      notes: this.notes,
    };
  }

  async save() {
    const db = DBFactory.createDatabase();
    await db.save(this.toMap(), this.databaseConfig);
  }
}
